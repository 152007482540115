@import "../../../style/global/global.module.scss";

.modal-content{border: none!important}

.modal-title{
    font-size: 1.15rem; 
    color: $gray-700; 
    font-family: $font-family-base;
}

.modal-body{
    padding: 30px; 
    position: relative; 
    font-family: $font-family-base; 
    font-size: 0.8rem
}