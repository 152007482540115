// PALETTE 
$white: #ffffff;
$black: #000000;

$gray: #6c757d ;
$primary: #7f56d9;
$danger: #d92d20;
$warning: #dc6803;
$success: #039855;
$blue-gray: #3e4784;
$blue: #1570ef;
$blue-light: #0086c9;
$indigo: #444ce7;
$purple: #6938ef;
$pink: #dd2590;
$rose: #e31b54;
$orange: #ec4a0a;

$gray-25: #fcfcfd;
$gray-50: #f9fafb;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

$primary-25: #fcfaff;
$primary-50: #f9f5ff;
$primary-100: #f4ebff;
$primary-200: #e9d7fe;
$primary-300: #d6bbfb;
$primary-400: #b692f6;
$primary-500: #9e77ed;
$primary-600: #7f56d9;
$primary-700: #6941c6;
$primary-800: #53389e;
$primary-900: #42307d;

$danger-25: #fffbfa;
$danger-50: #fef3f2;
$danger-100: #fee4e2;
$danger-200: #fecdca;
$danger-300: #fda29b;
$danger-400: #f97066;
$danger-500: #f04438;
$danger-600: #d92d20;
$danger-700: #b42318;
$danger-800: #912018;
$danger-900: #7a271a;

$warning-25: #fffcf5;
$warning-50: #fffaeb;
$warning-100: #fef0c7;
$warning-200: #fedf89;
$warning-300: #fec84b;
$warning-400: #fdb022;
$warning-500: #f79009;
$warning-600: #dc6803;
$warning-700: #b54708;
$warning-800: #93370d;
$warning-900: #7a2e0e;

$success-25: #f6fef9;
$success-50: #ecfdf3;
$success-100: #d1fadf;
$success-200: #a6f4c5;
$success-300: #6ce9a6;
$success-400: #32d583;
$success-500: #12b76a;
$success-600: #039855;
$success-700: #027a48;
$success-800: #05603a;
$success-900: #054f31;

$blue-gray-25: #fcfcfd;
$blue-gray-50: #f8f9fc;
$blue-gray-100: #eaecf5;
$blue-gray-200: #d5d9eb;
$blue-gray-300: #afb5d9;
$blue-gray-400: #717bbc;
$blue-gray-500: #4e5ba6;
$blue-gray-600: #3e4784;
$blue-gray-700: #363f72;
$blue-gray-800: #293056;
$blue-gray-900: #101323;

$blue-light-25: #f5fbff;
$blue-light-50: #f0f9ff;
$blue-light-100: #e0f2fe;
$blue-light-200: #b9e6fe;
$blue-light-300: #7cd4fd;
$blue-light-400: #36bffa;
$blue-light-500: #0ba5ec;
$blue-light-600: #0086c9;
$blue-light-700: #026aa2;
$blue-light-800: #065986;
$blue-light-900: #0b4a6f;

$blue-25: #f5faff;
$blue-50: #eff8ff;
$blue-100: #d1e9ff;
$blue-200: #b2ddff;
$blue-300: #84caff;
$blue-400: #53b1fd;
$blue-500: #2e90fa;
$blue-600: #1570ef;
$blue-700: #175cd3;
$blue-800: #1849a9;
$blue-900: #194185;

$indigo-25: #f5f8ff;
$indigo-50: #eef4ff;
$indigo-100: #e0eaff;
$indigo-200: #c7d7fe;
$indigo-300: #a4bcfd;
$indigo-400: #8098f9;
$indigo-500: #6172f3;
$indigo-600: #444ce7;
$indigo-700: #3538cd;
$indigo-800: #2d31a6;
$indigo-900: #2d3282;

$purple-25: #fafaff;
$purple-50: #f4f3ff;
$purple-100: #ebe9fe;
$purple-200: #d9d6fe;
$purple-300: #bdb4fe;
$purple-400: #9b8afb;
$purple-500: #7a5af8;
$purple-600: #6938ef;
$purple-700: #5925dc;
$purple-800: #4a1fb8;
$purple-900: #3e1c96;

$pink-25: #fef6fb;
$pink-50: #fdf2fa;
$pink-100: #fce7f6;
$pink-200: #fcceee;
$pink-300: #faa7e0;
$pink-400: #f670c7;
$pink-500: #ee46bc;
$pink-600: #dd2590;
$pink-700: #c11574;
$pink-800: #9e165f;
$pink-900: #851651;

$rose-25: #fff5f6;
$rose-50: #fff1f3;
$rose-100: #ffe4e8;
$rose-200: #fecdd6;
$rose-300: #fea3b4;
$rose-400: #fd6f8e;
$rose-500: #f63d68;
$rose-600: #e31b54;
$rose-700: #c01048;
$rose-800: #a11043;
$rose-900: #89123e;

$orange-25: #fffaf5;
$orange-50: #fff6ed;
$orange-100: #ffead5;
$orange-200: #fddcab;
$orange-300: #feb273;
$orange-400: #fd853a;
$orange-500: #fb6514;
$orange-600: #ec4a0a;
$orange-700: #c4320a;
$orange-800: #9c2a10;
$orange-900: #7e2410;
