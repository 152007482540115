/* open-sans-300 - latin */
@font-face {
     font-family: "Open Sans";
     font-style: normal;
     font-weight: 300;
     src: local(""),
          url("../webfonts/open-sans-v28-latin-300.woff2") format("woff2"), /* Super Modern Browsers */
          url("../webfonts/open-sans-v28-latin-300.woff") format("woff"), /* Modern Browsers */
}

/* open-sans-regular - latin */
@font-face {
     font-family: "Open Sans";
     font-style: normal;
     font-weight: 400;
     src: local(""),
          url("../webfonts/open-sans-v28-latin-regular.woff2") format("woff2"), /* Super Modern Browsers */
          url("../webfonts/open-sans-v28-latin-regular.woff") format("woff"), /* Modern Browsers */
}

/* open-sans-500 - latin */
@font-face {
     font-family: "Open Sans";
     font-style: normal;
     font-weight: 500;
     src: local(""),
          url("../webfonts/open-sans-v28-latin-500.woff2") format("woff2"), /* Super Modern Browsers */
          url("../webfonts/open-sans-v28-latin-500.woff") format("woff"), /* Modern Browsers */
}

/* open-sans-700 - latin */
@font-face {
     font-family: "Open Sans";
     font-style: normal;
     font-weight: 700;
     src: local(""),
          url("../webfonts/open-sans-v28-latin-700.woff2") format("woff2"), /* Super Modern Browsers */
          url("../webfonts/open-sans-v28-latin-700.woff") format("woff"), /* Modern Browsers */
}

/* open-sans-600 - latin */
@font-face {
     font-family: "Open Sans";
     font-style: normal;
     font-weight: 600;
     src: local(""),
          url("../webfonts/open-sans-v28-latin-600.woff2") format("woff2"), /* Super Modern Browsers */
          url("../webfonts/open-sans-v28-latin-600.woff") format("woff"), /* Modern Browsers */
}

/* open-sans-800 - latin */
@font-face {
     font-family: "Open Sans";
     font-style: normal;
     font-weight: 800;
     src: local(""),
          url("../webfonts/open-sans-v28-latin-800.woff2") format("woff2"), /* Super Modern Browsers */
          url("../webfonts/open-sans-v28-latin-800.woff") format("woff"), /* Modern Browsers */
}

/* open-sans-300italic - latin */
@font-face {
     font-family: "Open Sans";
     font-style: italic;
     font-weight: 300;
     src: local(""),
          url("../webfonts/open-sans-v28-latin-300italic.woff2") format("woff2"), /* Super Modern Browsers */
          url("../webfonts/open-sans-v28-latin-300italic.woff") format("woff"), /* Modern Browsers */
}

/* open-sans-italic - latin */
@font-face {
     font-family: "Open Sans";
     font-style: italic;
     font-weight: 400;
     src: local(""),
          url("../webfonts/open-sans-v28-latin-italic.woff2") format("woff2"), /* Super Modern Browsers */
          url("../webfonts/open-sans-v28-latin-italic.woff") format("woff"), /* Modern Browsers */

}

/* open-sans-500italic - latin */
@font-face {
     font-family: "Open Sans";
     font-style: italic;
     font-weight: 500;
     src: local(""),
          url("../webfonts/open-sans-v28-latin-500italic.woff2") format("woff2"), /* Super Modern Browsers */
          url("../webfonts/open-sans-v28-latin-500italic.woff") format("woff"), /* Modern Browsers */
}

/* open-sans-600italic - latin */
@font-face {
     font-family: "Open Sans";
     font-style: italic;
     font-weight: 600;
     src: local(""),
          url("../webfonts/open-sans-v28-latin-600italic.woff2") format("woff2"), /* Super Modern Browsers */
          url("../webfonts/open-sans-v28-latin-600italic.woff") format("woff"), /* Modern Browsers */

}

/* open-sans-700italic - latin */
@font-face {
     font-family: "Open Sans";
     font-style: italic;
     font-weight: 700;
     src: local(""),
          url("../webfonts/open-sans-v28-latin-700italic.woff2") format("woff2"), /* Super Modern Browsers */
          url("../webfonts/open-sans-v28-latin-700italic.woff") format("woff"), /* Modern Browsers */
}

/* open-sans-800italic - latin */
@font-face {
     font-family: "Open Sans";
     font-style: italic;
     font-weight: 800;
     src: local(""),
          url("../webfonts/open-sans-v28-latin-800italic.woff2") format("woff2"), /* Super Modern Browsers */
          url("../webfonts/open-sans-v28-latin-800italic.woff") format("woff"), /* Modern Browsers */
}
