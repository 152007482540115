// Font definitions 
$font-family-sans-serif:      "Inter";
$font-family-base:            $font-family-sans-serif;

$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-size-lg:                ($font-size-base * 1.125);
$font-size-md:                ($font-size-base * .85);
$font-size-sm:                ($font-size-base * .75);

$font-weight-regular:         400;
$font-weight-medium:          500;
$font-weight-semibold:        600;
$font-weight-bold:            700;

$font-weight-base:            $font-weight-medium;
$line-height-base:            1.25;

$border-radius-base:           8px;
$border-radius-small:          4px;

// Headings
$h1-font-size:                $font-size-base * 2.5;
$h2-font-size:                $font-size-base * 2;
$h3-font-size:                $font-size-base * 1.75;
$h4-font-size:                $font-size-base * 1.5;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base;

// Buttons
$input-btn-font-family:      "Inter";
$btn-font-family:             $input-btn-font-family;
$btn-font-weight:             $font-weight-medium;
$btn-border-radius:           $border-radius-base;
$btn-border-radius-lg:        $border-radius-base;
$btn-border-radius-sm:        $border-radius-base;

// Alerts
$alert-bg-level:                0;
$alert-border-level:            1;
$alert-color-level:             -10;

// Globals
$darkenaction :                20%;
$body-bg:                    #EAECF0;
$breadcrumb-divider:           "\f061";
$grid-gutter-width:            24px;

// Sidebar 
$sidebar-bg-color:           #101828;
$submenu-bg-color-collapsed: #101828;
$sidebar-color:                $gray-400;
$highlight-color:              $white;
$icon-size:                    25px;
$sidebar-collapsed-width:      65px;

// Forms 
$input-placeholder-color:      $gray-500;
$input-border-radius:          $border-radius-base;
$input-border-radius-large:    $border-radius-base;
$input-border-radius-large:    $border-radius-base;
$input-group-addon-color:      $primary;
$input-group-addon-bg:         $white;

//Bounding box color
$bounding-box-color: $success-300;

// Chart colors 
$chart-colors: (
    $primary,
    $blue,
    $success,
    $danger,
    $warning,
    $blue-gray,
    $blue,
    $blue-light,
    $indigo,
    $purple,
    $pink,
    $rose,
    $orange
    $gray
);

// Theme colors 
$theme-colors: (
    "primary":        $primary-500,
    "primary-200":    $primary-200,
    "primary-500":    $primary-500,
    "primary-700":    $primary-700,
    "secondary":      $gray-500,
    "secondary-200":  $gray-200,
    "secondary-500":  $gray-500,
    "secondary-700":  $gray-700,
    "success":        $success-500,
    "success-200":    $success-200,
    "success-500":    $success-500,
    "success-700":    $success-700,
    "info":           $blue-500,
    "info-200":       $blue-200,
    "info-500":       $blue-500,
    "info-700":       $blue-700,
    "warning":        $warning-500,
    "warning-200":    $warning-200,
    "warning-500":    $warning-500,
    "warning-700":    $warning-700,
    "danger":         $danger-500,
    "danger-200":     $danger-200,
    "danger-500":     $danger-500,
    "danger-700":     $danger-700,
    "light":          $gray-400,
    "dark":           $gray-800
);


    