@import "../../../style/global/global.module.scss";

.code-container {
    padding: 1.75rem 1.5rem;
    position: relative;
    background-color: $primary-100;
    border-radius: $btn-border-radius;

}

.copy-container {
    position: absolute;
    top: 5px;
    right: 10px;
    &:hover {
        cursor: pointer;
    }
}

.code-container code {
    font-family: $font-family-base;
    font-weight: $font-weight-medium;
    font-size: $font-size-sm;
}