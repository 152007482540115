@import "../../../style/global/global.module.scss";


.canvas-wrapper { position:relative } 
.canvas-header {border: 1px solid $gray-300; padding: 0.25rem 0 0 0;}
.canvas-header .dropdown .btn {border:none}
.canvas-header .dropdown .btn:hover, .canvas-header .dropdown .btn:active, .canvas-header .dropdown .btn:focus {background-color: $white!important; box-shadow: none!important;}
.canvas-header.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active, 
 .canvas-header .show > .btn.dropdown-toggle {
    background-color: $white!important;
    border-color: $white!important;
}
.canvas-header .show > .btn.dropdown-toggle:focus {
    box-shadow: none!important;
}
.canvas-content{position: relative}
.canvas-content > .canvas-container {z-index: 999;}
.canvas-overlay {
    width: max-content;
    position: absolute;
    top: 0
}
