@import "../../../style/global/global.module.scss";


.pro-sidebar-header {border-bottom: none!important;}
.pro-sidebar-header {border-bottom: none!important;}

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {padding-left: 0!important;}

.pro-sidebar .pro-menu {
    padding-top: 15px!important;
    padding-bottom: 15px!important;
}

.pro-menu > ul > .pro-menu-item {
    &.active {
      color: $gray-400!important;
      background-color: #F2F4F7;
      border-top-left-radius: $btn-border-radius;
      border-bottom-left-radius: $btn-border-radius;
      width: 85%;
      margin-left: 15%;
    }
}

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-sub-menu-item, .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item { padding: 8px 30px 8px 15px!important; }

.pro-menu-item {
    color: $gray-400;
    &:hover{
        color: $white
    }
}

.pro-icon {font-size: 1.1rem}

.popper-inner > ul > li.pro-menu-item  a {
    padding: 0!important;
}

.popper-inner > ul > li.pro-menu-item  ul > li a {
    color: $gray-400!important;
    text-decoration: none!important;
    padding: 0!important;
}

.popper-inner > ul > li.active a, .popper-inner > ul > li.pro-menu-item  ul > li.active a {
    color: $white!important;
    text-decoration: underline!important;
}

.pro-sidebar-content > div > div:last-child {left:2px!important}

@import "~react-pro-sidebar/dist/scss/styles.scss";