@import "../../../style/global/global.module.scss";

.alert {
    font-family: $font-family-base; 
}

.alert-dismissible .close {
    padding: 0 5px!important;
    text-shadow: none!important;
}

.alert i {margin-right: 15px;}


.alert-primary.outline {
    background-color: $white;
    border-color: $primary;
    color: $primary;
}

.alert-secondary.outline {
    background-color: $white;
    border-color: $gray;
    color: $gray;
}

.alert-success.outline {
    background-color: $white;
    border-color: $success;
    color: $success;
}

.alert-info.outline {
    background-color: $white;
    border-color: $blue;
    color: $blue;
}

.alert-warning.outline {
    background-color: $white;
    border-color: $warning;
    color: $warning;
}

.alert-danger.outline {
    background-color: $white;
    border-color:  $danger;
    color:  $danger;
}