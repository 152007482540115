@import "../../../style//global/global.module.scss";

.dropdown-item img {padding-right: 10px;}
.dropdown-header {
    padding: 0.25rem 1.5rem!important;
    color: $gray-700!important;
    font-style: italic;
}
.dropdown-divider {
    margin: 0.5rem 0 0 0!important;
}
.dropdown-item {
    font-size: 0.8rem;
    padding: 0.5rem 1.5rem!important;
}
.dropdown-item.active, .dropdown-item:active {

    color: $gray-700!important;
    background-color: $gray-200!important;
}