
@import "../../../style//global/global.module.scss";

@media only screen and (max-width: 768px) {
    .fc .fc-toolbar {
        display: block!important;
        width: 100%;
    }
}
.fc .fc-button {
    font-size: .8em;
}
.fc-daygrid-day-top {
    display: flex;
    flex-direction: inherit;
    align-items: center;
    justify-content: center;
}
.fc-daygrid-day-top a {
    font-family: $font-family-base;
    font-size: .8em;
}

.fc .fc-col-header-cell-cushion {
    display: inline-block;
    padding: 2px 4px;
    text-transform: uppercase;
    font-size: 0.8em;
    color: $gray-500
}

.fc-theme-standard th {
    border-bottom: none
}

.fc .fc-daygrid-day-number {
    color: $gray-600;
}
.event-circle {
    width: 12px;
    height: 12px;
    float: left;
    border-radius: 25%;
    margin-top: 7px;
    margin-right: 3px;
}

.fc .fc-daygrid-event {
    color: $gray-600;
    font-size: .75rem;
    font-family: $font-family-base;
}

.calendar-icon i{color: $gray-600}
.calendar-btn .btn {margin-right: 5px;}