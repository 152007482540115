@import "../../../style/global/global.module.scss";

ol.breadcrumb { /* breadcrumb bar */
	padding: 0;
	margin: 8px 0px 0px 0px;
}

.breadcrumb-item span { /* non active breadcrumb item */
	color: $gray-300;
	font-size: 0.7rem;
	font-weight: $font-weight-regular;
	font-family: $font-family-base;
	color: $gray-600;
}

.breadcrumb-item:last-child span {
	color: $gray-800;
}
.breadcrumb-item i {
	color: $gray-600; 
	font-size: .75rem;
}

.breadcrumb-item {
    + .breadcrumb-item {
        &::before {
            font-family: "Font Awesome 5 Free";
			font-weight: $font-weight-bold;
			content: $breadcrumb-divider;
			margin-top: 6px;
			font-size: .65rem;
        }
    }
}