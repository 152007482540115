
@import "../../../style/global/global.module.scss";

.header {background: $white;}

.navbar {padding: 0.5rem 0!important;}

.navbar-light .navbar-text {padding-right: 0.5rem; color: $gray-800!important; font-size: 1.2rem;}

.navbar-expand-md .navbar-nav {padding-left: 0.75rem;}

/*User menu*/
.navbar-nav {border-left: 1px solid $gray-200}

span.user-circle {
    background: $gray-200;
    padding: 7px;
    border-radius: 50%;
    margin-right: 10px;
}

.user-info { font-size: .75rem}
.user-signout { margin-left: 5px;}
