@font-face {
    font-family: 'Suisse Int\'l';
    src: url('../webfonts/SuisseIntl-BlackItalic.woff2') format('woff2'),
         url('../webfonts/SuisseIntl-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Int\'l Book';
    src: url('../webfonts/SuisseIntl-BookItalic.woff2') format('woff2'),
         url('../webfonts/SuisseIntl-BookItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Int\'l';
    src: url('../webfonts/SuisseIntl-BoldItalic.woff2') format('woff2'),
         url('../webfonts/SuisseIntl-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Int\'l';
    src: url('../webfonts/SuisseIntl-Black.woff2') format('woff2'),
         url('../webfonts/SuisseIntl-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Int\'l Book';
    src: url('../webfonts/SuisseIntl-Book.woff2') format('woff2'),
         url('../webfonts/SuisseIntl-Book.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Int\'l';
    src: url('../webfonts/SuisseIntl-Bold.woff2') format('woff2'),
         url('../webfonts/SuisseIntl-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Int\'l';
    src: url('../webfonts/SuisseIntl-Light.woff2') format('woff2'),
         url('../webfonts/SuisseIntl-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Int\'l';
    src: url('../webfonts/SuisseIntl-Thin.woff2') format('woff2'),
         url('../webfonts/SuisseIntl-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Int\'l Semi';
    src: url('../webfonts/SuisseIntl-SemiBold.woff2') format('woff2'),
         url('../webfonts/SuisseIntl-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Int\'l';
    src: url('../webfonts/SuisseIntl-Italic.woff2') format('woff2'),
         url('../webfonts/SuisseIntl-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Int\'l';
    src: url('../webfonts/SuisseIntl-Medium.woff2') format('woff2'),
         url('../webfonts/SuisseIntl-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Int\'l';
    src: url('../webfonts/SuisseIntl.woff2') format('woff2'),
         url('../webfonts/SuisseIntl.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Int\'l';
    src: url('../webfonts/SuisseIntl-MediumItalic.woff2') format('woff2'),
         url('../webfonts/SuisseIntl-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Int\'l';
    src: url('../webfonts/SuisseIntl-LightItalic.woff2') format('woff2'),
         url('../webfonts/SuisseIntl-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Int\'l Semi';
    src: url('../webfonts/SuisseIntl-SemiBoldItalic.woff2') format('woff2'),
         url('../webfonts/SuisseIntl-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Int\'l';
    src: url('../webfonts/SuisseIntl-UltraLightItalic.woff2') format('woff2'),
         url('../webfonts/SuisseIntl-UltraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Int\'l';
    src: url('../webfonts/SuisseIntl-ThinItalic.woff2') format('woff2'),
         url('../webfonts/SuisseIntl-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Int\'l';
    src: url('../webfonts/SuisseIntl-UltraLight.woff2') format('woff2'),
         url('../webfonts/SuisseIntl-UltraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}