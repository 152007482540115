@import "../../style/global/global.module.scss";
@import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.scss";

.form-control{ font-size: $font-size-md!important; }
.form-label { 
    color: $gray-500; 
    font-weight: $font-weight-semibold; 
    font-size: $font-size-sm; 
    margin-bottom: 0.25rem; 
}
.form-check-label {
    font-size: $font-size-sm;
    font-weight: $font-weight-medium;
    font-family: $font-family-base;
    margin-top: 0.25rem;
}
.custom-control-label  {
    font-size: $font-size-sm;
    font-weight: $font-weight-medium;
    font-family: $font-family-base;
    padding-top: 0.15rem;
}

input[type=checkbox]:checked + label.form-check-label, .custom-control-input:checked~.custom-control-label::before {font-weight: $font-weight-semibold;} 
.input-group { margin-bottom: $font-size-base;}
.input-group-text {font-size: $font-size-md!important;}
.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: $font-size-sm;
}
.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-size: $font-size-sm;
}
.form-control::-ms-input-placeholder { /* Microsoft Edge */
    font-size: $font-size-sm;
}

.dropzone {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6% 0 6% 0;
    border-radius: $border-radius-base;
    background-color: $gray-100;
    border: 1px dashed $primary-300;
    color: $gray-700;
    outline: none;
    transition: border .24s ease-in-out;
    font-family: $font-family-base;
    margin: 3px;
    &::before {
        font-family: 'Font Awesome 5 Free';
        font-weight: $font-weight-bold;
        content: '\f382';
        color: $primary-500;
        font-size: 1.75rem;
    }
}

.dropzone p {
    font-family: $font-family-base;
    font-size: $font-size-sm;
    margin-bottom: 0;
}

.acceptedfile-list {list-style-type: circle!important; margin-top: 1rem;}

.range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label {opacity: 1!important;}

/* slider and range styles */
.range-slider {
    position: relative;
    width: 100%;
    height: 45px;
}

.range-rail {
    position: absolute;
    width: 100%;
    height: 4px;
    margin-top: 25px;
    background-color: $gray-400;
    border-radius: $border-radius-base;
}

.slider-handles.primary > div:not(.tooltip-range__container) { background: $primary-500; }
.slider-handles.secondary > div:not(.tooltip-range__container) { background: $gray-500; }
.slider-handles.success > div:not(.tooltip-range__container) { background: $success-500; }
.slider-handles.info > div:not(.tooltip-range__container) { background: $blue-500; }
.slider-handles.warning > div:not(.tooltip-range__container) { background: $warning-500; }
.slider-handles.danger > div:not(.tooltip-range__container) { background: $danger-500; }

.tooltip-range {
    display: block;
    position: absolute;
    width: 0;
    height: 26px;
    text-align: center;
    overflow: visible;
    transition: opacity 0.15s linear;
    z-index: 1070;
}

.tooltip-range .tooltip__label {
    position: absolute;
    transform: translateX(-50%);
    max-width: 200px;
    padding: 0 0.5rem;
    height: 26px;
    line-height: 26px;
    color: $white;
    text-align: center;
    background-color: $black;
    opacity: 0.9;
    border-radius: 0.25rem;
    box-sizing: border-box;
    font-size: 0.875rem;
    white-space: nowrap;
}
  
.tooltip-range .tooltip__arrow {
    position: absolute;
    transform: translateX(-50%);
    display: block;
    width: 0.8rem;
    height: 0.4rem;
    opacity: 0.9;
}

.tooltip-range .tooltip__arrow.bottom {
    top:-6px
}
  
.tooltip-range .tooltip__arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
    left: 0;
}

.tooltip-range .tooltip__arrow.bottom::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: $black;
}