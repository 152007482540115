@import "../../../style//global/global.module.scss";

.btn.btn-md { 
    font-size: $font-size-md;
}

.btn.btn-lg { 
    font-size: $font-size-lg;
}

.btn.btn-sm { 
    font-size: $font-size-sm;
}

