@import "./_colors.scss";
@import "./_fontstyles.scss";
@import "./_theme.scss";
@import "../../../custom.scss";


:export {
    primary: $primary-500;
    primary200: $primary-200;
    warning: $warning-500;
    warning200: $warning-200;
    danger: $danger-500;
    danger200: $danger-200;
    success: $success-500;
    success200: $success-200;
    info: $blue-500;
    info200: $blue-200;
    chartColors: $chart-colors;
    boundingBoxColor: $bounding-box-color
}