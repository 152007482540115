@import "../../../style/global/global.module.scss";

.loader-absolute {
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.65);
}
  
.loader-absolute .spinner-grow, .loader-absolute .spinner-border {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

  