@import "../../../style/global/global.module.scss";

.Toastify__toast-container {
    margin-top: 35px;
    z-index: 9999;
}

.Toastify__toast-body {
    font-size: .85rem;
}

.Toastify__toast--success {
    background-color: $success-500;
}

.Toastify__toast--error {
    background-color: $danger-500;
}

.Toastify__toast--warning {
    background-color: $warning-500;   
}

.Toastify__toast--info {
    background-color: $blue-500; 
}
