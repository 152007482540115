@import "../../../style/global/global.module.scss";

.footer{padding: 10px; background-color: $white;}
.copy-info {
    font-size: .7rem;
    margin: 0;
    color: $gray-600;
}
.princess-info {
    font-size: .7rem;
    margin: 0;
    text-align: right;
    color: $gray-600;
}
.copy-info a, .princess-info a {margin-left: 3px;}

@media screen and (max-width: 992px) {
    .copy-info {margin-bottom: 5px;}
    .copy-info, .princess-info {
        text-align: center;
    }
}
