.panel{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-shadow: 0 0 30px 0 rgb(82 63 105 / 5%);
    box-shadow: 0 0 30px 0 rgb(82 63 105 / 5%);
    border-radius: 4px;
    margin: 12px 0;
    padding: 0;
    background-color: #FFF;
}

.panel__body{padding: 30px; position: relative; font-family: 'Inter'; font-size: 0.8rem}
.panel__head{padding: 15px 15px 5px 15px; border-bottom: 1px solid #f2f3f8;}
.panel__head h3 {font-size: 1.15rem; color: #74788d; font-family: 'Inter';}

.panel.danger{background-color: #CC2A49;}
.panel.warning{background-color: #FCBB6D;}
.panel.info{background-color: #17a2b8; }
.panel.success{background-color: #4AB19D}
.panel.danger, .panel.danger .panel__head h3, 
.panel.warning, .panel.warning .panel__head h3,
.panel.info, .panel.info .panel__head h3,
.panel.success, .panel.success .panel__head h3 {color: #FFF}
.panel.transparent {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.hide {display: none;}

