.absolute-center {
  margin: auto;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
}
  
.absolute-center.half-width {
  width: 50%; 
  height: 60%;
  max-width: 400px;
}
  
.absolute-center.full-width {
  width: 100%; 
  height: 100%;
}
  