@import "../../../style/global/global.module.scss";

.tooltip-inner {
    background-color: $gray-500;
    font-family: $font-family-base;
    font-weight: $font-weight-medium;
    font-size: .875rem;
    padding: 0px 0.5rem;
}

.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {
    border-right-color: $gray-500;
}

.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {
    border-top-color: $gray-500;
}

.bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-left .arrow::before {
    border-left-color: $gray-500;
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before {
    border-bottom-color: $gray-500;
}